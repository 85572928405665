import React from "react";

import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { auth, db } from "./firebase";

import "survey-core/defaultV2.css";
// import "survey-core/modern.min.css";
import "./css/Survey.css";
import { json } from "./feedback"

StylesManager.applyTheme("defaultV2");

function SurveyComponent() {
    const navigate = useNavigate();
    const survey = new Model(json);
    const [user] = useAuthState(auth);
    
    const writeSurvey = async (results) => {
      const docRef = doc(db, "users", user?.uid);
      var suggestions = "Empty";
      console.log(results);
      if(results["suggestions"]){
        suggestions = results["suggestions"];
      }
      try{
        await updateDoc(docRef, {
          survey: {
            date: new Date().toLocaleDateString('fr-CH'),
            helpful: results["helpful"],
            understand: results["understand"],
            suggestions: suggestions
          }
        });
        navigate("/dashboard");
      }
      catch(e){
        console.error("Error adding document: ", e);
        navigate("/dashboard");
      }

    }

    const alertResults = useCallback((sender) => {
        const results = sender.data;
        writeSurvey(results);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    survey.onComplete.add(alertResults);

    return (<Survey model={survey} />);
}

export default SurveyComponent;