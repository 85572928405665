import React, { useEffect, useRef, useState } from "react";
import microphone from "./assets/mic_circle.png"
import { Spinner } from 'react-bootstrap';
import { BsList } from 'react-icons/bs';
import { speechRecognitionError, wait } from "./toasts.js"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/Dashboard.css';
import './css/index.css';
import animated from "./assets/active-crop.gif"
import still from "./assets/still-crop.gif"
import trash from "./assets/trash.png"
import { SpeechRecognition } from "@capacitor-community/speech-recognition";

const areEqual = (prevProps, nextProps) => {
    // Check if all props are the same
    const allPropsEqual = Object.keys(prevProps).every(key => prevProps[key] === nextProps[key]);

    // If not all props are the same, log the props that have changed
    // if (!allPropsEqual) {
    //     console.log('Changed props:', Object.keys(prevProps).filter(key => prevProps[key] !== nextProps[key]));
    // }

    // Return the result of the comparison
    return allPropsEqual;
};

const DashVoiceComponent = ({ accent_color, input, heightBody, heightHeader, position, topmargin, setSidebarOpen, setInput, isRecording, onEnterPress, textclick, setIsRecording, platform, handleSubmit, speaking, playSpeech, receiving, subscribe_active, subscribeStatus, subscribeTier, standardUsage, total_msg, userStatus, num_free_messages, num_standard_messages, setShowSubscriptionModal }) => {
    const width = window.innerWidth * 0.8;
    const height = window.innerHeight
    const marginLeft = 0.1 * window.innerWidth
    const textRef = useRef(null);
    const fullMsg = useRef("");
    const recordRef = useRef("");

    const [showSpeechVideo, setShowSpeechVideo] = React.useState(false);
    const [showThinkVideo, setShowThinkVideo] = React.useState(false);
    const [settingUpSpeech, setSettingUpSpeech] = useState(false);

    const stopSpeechRecognition = async (submit) => {

        try {
            // stop listening partial results
            SpeechRecognition.stop();
            SpeechRecognition.removeAllListeners();
            if (submit) {
                await handleSubmit()
            }

            recordRef.current = ""
            fullMsg.current = ""
        }
        catch (error) {
            console.log(error)
        }
    }

    // Delete the current message recorded by the speech recognition, but allow user to keep recording
    const deleteSpeech = async () => {
        if (input !== "") {
            try {
                setInput("");
                recordRef.current = "";
                fullMsg.current = "";
                // stop listening partial results
                SpeechRecognition.stop();
                SpeechRecognition.removeAllListeners();


                // Sleep for 200ms prevents crash on iOS, no idea why
                await new Promise(resolve => setTimeout(resolve, 200));

                await startSpeechRecognition();
            } catch (error) {
                console.log(error);
            }
        }
    }

    const startSpeechRecognition = async (event) => {
        try {
            await SpeechRecognition.start({
                language: "en-US",
                maxResults: 1,
                prompt: "Say something",
                partialResults: true,
                popup: false,
            }).catch(error => console.log(error));
            // listen to partial results
            await SpeechRecognition.addListener("partialResults", (data) => {
                // recordRef and fullMsg only needed on Android, the problem is that after a short pause data.matches[0] is empty
                // this logic stitches together the partial results to the full message
                if (platform === "android") {
                    if (data.matches[0] !== "") {
                        recordRef.current = data.matches[0]
                        setInput(fullMsg.current + data.matches[0])
                    }
                    else {
                        fullMsg.current += recordRef.current
                    }
                }
                else if (platform === "ios") {
                    setInput(data.matches[0])
                }
            }).catch(error => console.log(error));
            // listen for errors
            SpeechRecognition.addListener("errorSpeech", (error) => {
                speechRecognitionError()
            });
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (speaking) {
            setShowThinkVideo(false)
            setShowSpeechVideo(true)
        }
        else {
            setShowSpeechVideo(false)
        }
    }, [speaking]);

    useEffect(() => {
        textRef.current.scrollTop = textRef.current.scrollHeight;
    }, [input])

    const handleClick = async (e) => {
        try {
            let permission = await SpeechRecognition.hasPermission()
            if (permission.permission !== true) {
                if (!receiving) {
                    // This is just done because on ios the speech recognition and speech playback does otherwise not immediately work for the user
                    if (platform === "ios") {
                        setSettingUpSpeech(true)
                        await SpeechRecognition.requestPermissions()
                        await SpeechRecognition.requestPermission()
                        startSpeechRecognition()
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        stopSpeechRecognition(false)
                        playSpeech(" ", false)
                        await new Promise(resolve => setTimeout(resolve, 3000));
                        setSettingUpSpeech(false)
                        return
                    }

                    else {
                        await SpeechRecognition.requestPermissions()
                        await SpeechRecognition.requestPermission()
                        return
                    }
                }
                else{
                    wait()
                }
            }
            else {
                if (subscribe_active && total_msg >= num_free_messages && subscribeStatus !== "active" && userStatus !== "admin") {
                    setShowSubscriptionModal(true)
                }
                else if (subscribe_active && standardUsage >= num_standard_messages && subscribeTier === "Standard Plan" && subscribeStatus === "active" && userStatus !== "admin") {
                    setShowSubscriptionModal(true)
                }
                else {
                    if (!receiving) {
                        if (!isRecording) {
                            setIsRecording(true)
                            startSpeechRecognition()
                        }
                        else {
                            stopSpeechRecognition(true)
                            setIsRecording(false)
                            // videoThinkRef.current.currentTime = 0;
                            if (input !== "") {
                                setShowThinkVideo(true)
                            }
                        }
                    }
                    else{
                        wait()
                    }
                }
            }
        } catch (err) {
            console.log(err)
        }

    }
    // const handleTouchStart = (e) => {
    //     if (platform === "ios") {
    //         if (!isRecording) {
    //             startSpeechRecognition()
    //         }
    //     }
    // }

    return (
        <div>
            <ToastContainer />
            <div className="card border-0 p-0 d-flex" id="chat2" style={{ borderRadius: 0, top: "0", height: heightBody, backgroundColor: "#ffffff" }}>

                <div className="card-header border-0 d-flex justify-content-between align-items-center p-3" style={{ borderRadius: 0, backgroundColor: accent_color, height: heightHeader, position: position, top: "0", left: "0", right: "0", zIndex: "300" }}>
                    <div className="text-center" style={{ marginTop: topmargin }}>
                        <span className="logo me-auto">
                            <strong className="h1titlemobile">SERENA</strong></span>
                    </div>
                    <button onClick={() => setSidebarOpen(true)} style={{ background: accent_color, color: "white", border: "none", borderRadius: "5px", padding: "5px", marginTop: topmargin }}>
                        <BsList size={28} />
                    </button>
                </div>
                <div style={{ paddingTop: height * 0.3, backgroundColor: "#ffffff" }}>
                    {showThinkVideo ?
                        <img
                            src={still}
                            alt="GIF"
                            width={window.innerWidth}
                        />
                        : null}
                    {showSpeechVideo ?
                        <img
                            src={animated}
                            alt="GIF"
                            width={window.innerWidth}
                        />
                        : null}
                    {/* <video
                        ref={videoThinkRef}
                        src="videos/video_still.mp4"
                        autoPlay
                        loop
                        muted
                        style={{ visibility: showThinkVideo ? 'visible' : 'hidden' }}
                        playsInline
                        width={window.innerWidth}
                        type="video/mov"
                        onError={(e) => {
                            // Log the MediaError object
                            console.error('Video error:', e.target.error);
                        }}>
                        Your browser does not support the video tag.
                    </video>  */}
                    <textarea
                        className="form-control form-rounded input"
                        onKeyDown={onEnterPress}
                        onClick={textclick}
                        value={input}
                        id="exampleFormControlInput1"
                        placeholder=""
                        row="1"
                        style={{ position: "fixed", top: 0.6 * height, height: 0.2 * height, width: width, backgroundColor: "#ffffff", borderRadius: "1.5rem", zIndex: 0, marginLeft: marginLeft, marginRight: marginLeft, color: "#141414", marginTop: "0%", resize: "none", overflowY: "auto" }}
                        ref={textRef}
                        readOnly
                    >
                    </textarea>
                </div>

                <div style={{ top: height * 0.8, position: 'fixed', justifyContent: "center", display: "flex", width: '100%' }}>
                    {/* Setting up phase is necessary only after giving permission to speech recognition. This is because the speech recognition does not work immediately after giving permission but we have to sleep for 2 seconds*/}
                    {settingUpSpeech ?
                        <>
                            <div className="text-center" style={{
                                position: "fixed",
                                color: "#949494", zIndex: 1, width: "100%", marginLeft: "5px"
                            }}>Setting up ...
                            </div>
                            <button type="button"
                                className="noborder buttonspace"
                                // onTouchStart={handleTouchStart}
                                style={{ zIndex: 1, marginTop: 0.1 * width }}
                                onContextMenu={(e) => e.preventDefault()}>
                                <Spinner animation="border" role="status" style={{ animationDuration: '1s', color: accent_color, height: 0.25 * width, width: 0.25 * width }}>
                                    <span className="sr-only"></span>
                                </Spinner>
                            </button>
                        </>
                        :
                        <>
                            <div className="text-center" style={{
                                position: "fixed",
                                color: "#949494", zIndex: 1, width: "100%", marginLeft: "5px"
                            }}>{isRecording ? "Tap again to send" : "Tap to record"}</div>
                            <button type="button"
                                className="noborder buttonspace"
                                onClick={handleClick}
                                // onTouchStart={handleTouchStart}
                                style={{ zIndex: 1, marginTop: 0.1 * width }}
                                onContextMenu={(e) => e.preventDefault()}>
                                {isRecording ?
                                    <Spinner animation="grow" role="status" style={{ animationDuration: '1s', color: accent_color, height: 0.25 * width, width: 0.25 * width }}>
                                        <span className="sr-only"></span>
                                    </Spinner>
                                    :
                                    <img src={microphone} width={0.25 * width} alt="send button" onContextMenu={(e) => e.preventDefault()} style={{ WebkitTouchCallout: 'none' }} />
                                }
                            </button>
                        </>
                    }


                    {isRecording ?
                        <button type="button" className="noborder buttonspace" onClick={deleteSpeech} style={{ position: 'fixed', zIndex: 1, marginLeft: 0.9 * width, marginTop: 0.1 * width + 20 }}>
                            <img src={trash} width={0.1 * width} alt="send button" />
                        </button>
                        : null}
                    <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'white' }}></div>
                </div>
            </div>
        </div>

    )
}

const MemoizedDashVoiceComponent = React.memo(DashVoiceComponent, areEqual)

export default MemoizedDashVoiceComponent;