import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
    auth,
    db
} from "./firebase";
import "./css/Register.css";
import "./css/Login.css";
import logorounded from "./assets/icon-512.webp"
import {
    updateProfile,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { Capacitor } from "@capacitor/core"
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';

import { accent_color } from "./variables.js"

const getStatusBarHeight = async () => {
    const { height } = await SafeArea.getStatusBarHeight();
    return height;
  };

let topmargin
let welcomeTopMargin
let platform
if (Capacitor.isNativePlatform()) {
    platform = Capacitor.getPlatform();
    getStatusBarHeight().then((val) => {
      topmargin = val;
      if (platform === "ios") {
        welcomeTopMargin = val + 0.18*window.screen.availHeight
      }
      else{
        welcomeTopMargin = 0.2*window.screen.availHeight

      }
    })
  
  }
  else {
    welcomeTopMargin = 0.1*window.screen.availHeight
    topmargin = "0px"
    platform = "web"
  }

function Welcome() {
    const [name, setName] = useState("");
    const [user, loading] = useAuthState(auth);
    const [marginForm, setMarginForm] = useState("0px");
    const navigate = useNavigate();

    const [checked, setChecked] = React.useState(false);

    const mediaMatch = window.matchMedia('(max-width: 1000px)');
    // matches = True means we are on a display with small width
    const [matches, setMatches] = useState(mediaMatch.matches);
    const [margin, setMargin] = useState("-7.5px");

    useEffect(
        function initialization() {
            if (loading) return;
            if (!user) return navigate("/");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user, loading]);


    useEffect(() => {
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener("change", handler);
        return () => mediaMatch.removeEventListener("change", handler);
    });

    useEffect(() => {
        if (matches) {
            setMargin("0px");
            setMarginForm("-20px");
        }
    }, [matches]);

    const handleChange = () => {
        setChecked(!checked);
    };

    const register = async () => {
        if (!checked) alert("Accept Privacy Policy and Terms of Service")
        else {
            await updateProfile({ displayName: name }).catch(error => console.log("error", error));
            const docRef = doc(db, "users", user?.uid);
            await updateDoc(docRef, { "name": name }).catch(error => console.log("error", error));
            navigate("/dashboard");
        }
    };

    return (
        <div>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">

                    <a href="http://serena.chat" className="logo me-auto" style={{ "marginLeft": margin, "marginTop": topmargin }}><img src={logorounded} alt="" className="img-fluid" />
                        <strong className="h1title">Serena</strong></a>
                </div>
            </header>
            <div className="container ex-2-header" style={{ "position": "fixed" }}>
                <div className="row">
                    <div className="col-lg-12" style={{ position: "absolute", top: welcomeTopMargin}}>
                        <h1 style={{ marginBottom: "2rem" }}>Welcome!</h1>
                        <p>
                            <span className="bodymyfont">
                                To continue, please accept our Privacy Policy and Terms of Service.
                            </span>
                        </p>
                        {/* Sign Up Form */}
                        <div className="form-container" style={{ "marginTop": marginForm }}>
                            <div className="form-group">
                                {/* //add a small vertical space */}
                                {/* <input
                                    type="text"
                                    className="form-control-input inputmyfont"
                                    id="sname"
                                    required=""
                                    placeholder="Username"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ "marginBottom": "10px" }}
                                /> */}
                             
                                <div className="form-group checkbox checkboxes">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id="sterms"
                                            defaultValue="Agreed-to-Terms"
                                            required=""
                                            checked={checked}
                                            onChange={handleChange}
                                        />

                                        <span>
                                            I agree with the {" "}
                                            <a href="https://serena.chat/legal#privacy" style={{ color: accent_color }} >Privacy Policy</a> and{" "}
                                            <a href="https://serena.chat/legal#terms" style={{ color: accent_color }}>Terms of Service</a>
                                        </span>
                                    </label>

                                </div>
                                <div className="form-group">
                                    <button className="form-control-submit-button buttonmyfont" onClick={register}>
                                        Let's go
                                    </button>
                                </div>
                            </div>
                            <div className="form-message">
                                <div id="smsgSubmit" className="h3 text-center hidden" />
                            </div>
                            {/* <a className="white" href="http://localhost:8000" style={{ color: "#696fe2", "font-size": "14px" }} >
              Go back
            </a> */}
                        </div>{" "}

                        {/* end of form container */}
                        {/* end of sign up form */}
                    </div>{" "}
                    {/* end of col */}
                </div>{" "}
                {/* end of row */}
            </div>{" "}
            {/* end of container */}

        </div>
    );
}

export default Welcome;