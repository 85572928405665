import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  initializeAuth,
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  indexedDBLocalPersistence,
  signInWithEmailAndPassword,
  signInWithCredential,
  signOut,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  sendPasswordResetEmail,
  deleteUser
} from "firebase/auth"
import { initializeApp } from "firebase/app"
import { Capacitor } from "@capacitor/core"
import {
  getFirestore,
  getDoc,
  setDoc,
  doc,
  deleteDoc
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDPjJcnioB69sA73zVgqfoYXel0UqHEtYE",
  authDomain: "auth.serena.chat",
  // authDomain: "aitherapyapi.firebaseapp.com",
  projectId: "aitherapyapi",
  storageBucket: "aitherapyapi.appspot.com",
  messagingSenderId: "595110206151",
  appId: "1:595110206151:web:fbacbc4fcb54b90f98fce2",
  measurementId: "G-WV7T86637X"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)


const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
// const appleProvider = new OAuthProvider('apple.com');

let auth
if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(app, {
    persistence: indexedDBLocalPersistence,
  })
} else {
  auth = getAuth()
}

const userSetup = async (user) => {
  const email = user.email
  const docRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return
  }
  else {
    await updateProfile(user, { displayName: "__init" }).catch(error => console.log("error", error));
    await setDoc(docRef, {
      "name": "__init",
      authProvider: "local",
      email,
      survey: "",
      "history": "", //this is for the currrent session (deleted on clicking restart)
      "archive": "", //here we save all the messages for this user (if opt-in, never deleted)
      "msg_cnt": { today: 0 },
      "status": "user",
      "archive_anon": true,
      "archive_user": false,
      "total_msg": 0,
      "msg_id": 0,
      "lang": "EN",
      "history_lang": "",
    })
  }
}

const signInWithApple = async () => {
  if (Capacitor.isNativePlatform()) {
    // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithApple({
        skipNativeAuth: true,
      });
      if(!result){alert("Apple sign in failed"); return}
      // 2. Sign in on the web layer using the id token and nonce
      const provider = new OAuthProvider('apple.com');
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce,
      });

      const res = await signInWithCredential(auth, credential);
      await userSetup(res.user)
  }
  else {
      const res = await signInWithPopup(auth, appleProvider)
      await userSetup(res.user)
  }
};

const signInWithGoogle = async () => {
  if (Capacitor.isNativePlatform()) {
      // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithGoogle();
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(result.credential?.idToken);
      const res = await signInWithCredential(auth, credential);
      await userSetup(res.user)
    }
  else {
      const res = await signInWithPopup(auth, googleProvider)
      await userSetup(res.user)
      return res.user
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const result1 = await signInWithEmailAndPassword(auth, email, password)
    return result1
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}


const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await sendEmailVerification(user)
      .catch(error => console.log(error));
    await userSetup(user)
    signOut(auth);
    return "success"
  } catch (err) {
    console.error(err);
    alert(err.message);
    return "error"
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
    return "success"
  } catch (err) {
    console.error(err);
    alert(err.message);
    return "error"
  }
};

const logout = () => {
  signOut(auth);
};

const removeUser = async (user) => {
  try {
    await deleteDoc(doc(db, "users", user.uid))
    await deleteUser(user)
    return "success"
  }
  catch (err) {
    console.error(err);
    return "error"
  }
};

export { logInWithEmailAndPassword, signInWithApple, signInWithGoogle, db, auth, logout, registerWithEmailAndPassword, sendPasswordReset, removeUser }
