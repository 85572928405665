import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';

const getStatusBarHeight = async () => {
  const { height } = await SafeArea.getStatusBarHeight();
  return height;
};

export const checkKeyboard = (inputRef, passwordRef, signInTopMargin, setTopMargin) => {
  setTimeout(() => {
    if (inputRef.current) {
      const emailPosY = inputRef.current.getBoundingClientRect().bottom
      let keyboardPosY
      getStatusBarHeight().then((val) => {
        keyboardPosY = window.innerHeight - val
        if (keyboardPosY < emailPosY) {
          console.log("Keyboard is covering the email input")
          setTopMargin(signInTopMargin - (emailPosY - keyboardPosY + 50))
        }
      })
    }
    else if (passwordRef.current) {
      const passwordPosY = passwordRef.current.getBoundingClientRect().bottom
      let keyboardPosY
      getStatusBarHeight().then((val) => {
        keyboardPosY = window.innerHeight - val
        if (keyboardPosY < passwordPosY) {
          console.log("Keyboard is covering the password input")
          setTopMargin(signInTopMargin - (passwordPosY - keyboardPosY + 50))
        }
      })
    }
  }, 1500);
};

const throwOnTimeout = (timeout) =>
  new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeout))

export const fetchWithTimeout = (url, options = {}) => {
  const { timeout, ...remainingOptions } = options
  // if the timeout option is specified, race the
  // fetch call
  if (timeout) {
    return Promise.race([fetch(url, remainingOptions), throwOnTimeout(timeout)])
  }
  return fetch(url, remainingOptions)
}