export const json = {
  "title": "Feedback",
  "description": "We would love to hear your feedback about Serena!",
  "questions": [
   {
      "type": "rating",
      "name": "understand",
      "title": "How well did the chatbot understand your messages?",
      "minRateDescription": "Very poorly",
      "maxRateDescription": "Excellently",
    },      {
      "type": "rating",
      "name": "helpful",
      "title": "Did you find talking to the chatbot helpful?",
      "minRateDescription": "Not helpful",
      "maxRateDescription": "Very helpful",
    }, 
    {
      type: "comment",
      name: "suggestions",
      title: "Do you have any suggestions how we can improve Serena?"
    }
  ]
  };
  