import { useState, useEffect } from 'react';
import './css/Loading.css'; // Import the CSS file
import "./css/Login.css";

const LoadingAnimation = () => {
  // state to track the number of dots
  const [dotsCount, setDotsCount] = useState(0);

  useEffect(() => {
    // add dots up to a maximum of 3
    const interval = setInterval(() => {
      setDotsCount(prevDotsCount => (prevDotsCount >= 3 ? 0 : prevDotsCount + 1));
    }, 500); // have half-second intervals

    return () => clearInterval(interval); // clear the interval on component unmount
  }, []);

  const dots = Array.from({length: 3}, (_, i) => i < dotsCount ? '.' : '\xa0');
  
  return <div className="LoadingAnimation col-lg-12" style={{ position: "absolute", top: "30vh" }}><h1>Signing you in</h1><br></br><h1>{dots}</h1></div>;
};

export default LoadingAnimation;