import React from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { BsList } from 'react-icons/bs';

import 'react-toastify/dist/ReactToastify.css';
import './css/Dashboard.css';
import './css/index.css';

const DashTextComponent = ({ accent_color, messages, receiving, handleRetry, clickCount, input, setInput, inputRef, handleSubmit, paperplane, heightBody, heightHeader, position, topmargin, setSidebarOpen, containerRef, MessageList, buttonMargin, isRecording, stopSpeechRecognition, startSpeechRecognition, onEnterPress, textclick, onMobile, height, width }) => {
  if (!onMobile) {
    // This is return on large displays 
    return (

      <section style={{ backgroundColor: "#eee" }}>

        <div className="container-fluid fill" style={{ height: height, width: width, background: "#eee" }}>

          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <div className="card border-0" id="chat2" style={{ borderRadius: 15, top: "10vh" }}>
                <div className="card-header d-flex justify-content-between align-items-center p-3" style={{ backgroundColor: accent_color, borderTopLeftRadius: 15, borderTopRightRadius: 15 }}>
                  <div className="text-center">
                    {/* <h5 className="mb-0 text-white">Serena</h5> */}
                    <span className="logo me-auto" style={{ marginLeft: "0px" }}>
                      <strong className="h1title">SERENA</strong></span>
                  </div>
                  <div className="text-right">
                    <button onClick={() => setSidebarOpen(true)} style={{ background: accent_color, color: "white", border: "none", borderRadius: "5px", padding: "10px" }}>
                      <BsList size={25} />
                    </button>
                  </div>
                </div>
                <div
                  className="card-body"
                  data-mdb-perfect-scrollbar="true"
                  style={{ position: "relative", height: "55vh", overflow: "auto" }}
                >
                  <MessageList items={messages} receiving={receiving} handleRetry={handleRetry} clickCount={clickCount} />
                </div>
                {/* backgroundColor: "#ffffff", borderColor: "#9B9B9B", color: "#636363", */}
                <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3" style={{ backgroundColor: "#fff", borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>
                  <TextareaAutosize
                    className="form-control form-rounded"
                    onKeyDown={onEnterPress}
                    value={input}
                    onChange={event => setInput(event.target.value)}
                    id="exampleFormControlInput1"
                    placeholder="Type message"
                    rows="1"
                    style={{ overflow: "auto", height: "auto" }}
                    ref={inputRef}>
                  </TextareaAutosize>
                  <button type="button" className="noborder buttonspace" onClick={handleSubmit}><img src={paperplane} width="32" height="32" alt="" /></button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>
    );
  }
  else {
    //
    //
    //
    //
    //
    // this is return on small, mobile devices
    //
    //
    //
    //
    return (
      <div>
          <div className="card border-0 p-0 d-flex" id="chat2" style={{ borderRadius: 0, top: "0", height: heightBody }}>

            <div className="card-header border-0 d-flex justify-content-between align-items-center p-3" style={{ borderRadius: 0, backgroundColor: accent_color, height: heightHeader, position: position, top: "0", left: "0", right: "0", zIndex: "300" }}>
              <div className="text-center" style={{ marginTop: topmargin }}>
                <span className="logo me-auto">
                  <strong className="h1titlemobile">SERENA</strong></span>
              </div>
              <button onClick={() => setSidebarOpen(true)} style={{ background: accent_color, color: "white", border: "none", borderRadius: "5px", padding: "5px", marginTop: topmargin }}>
                <BsList size={28} />
              </button>
            </div>

            <div ref={containerRef} className="card-body pb-2 flex-grow-1 flex-col" data-mdb-perfect-scrollbar="true" style={{ position: "relative", overflow: "auto", height: "100vh" }}>
              <MessageList items={messages} receiving={receiving} handleRetry={handleRetry} clickCount={clickCount} />

              {/* This empty div adds space to the bottom of the chat while receiving a message */}
              <div className="d-flex justify-content-center align-content-center">
                {(messages.length % 2 !== 0 && messages.length > 2 && receiving === true) ? <div style={{ position: "relative", top: -10, fontSize: 12, marginBottom: buttonMargin }}></div> : <div></div>}
              </div>
              <div className="d-flex justify-content-center align-content-center">
              </div>
            </div>
            <div className="card-footer text-muted d-flex justify-content-start align-items-center p-3" style={{ position: position, height: "3.5rem", bottom: "0", backgroundColor: "#fff", borderTop: "none" }}>
              {/* {isRecording ? <button type="button" className="noborder buttonspace" onClick={stopSpeechRecognition} style={{ marginLeft: "-5px", marginRight: '5px' }}>
                <Spinner animation="grow" role="status" style={{ animationDuration: '1s', color: accent_color }}>
                  <span className="sr-only"></span>
                </Spinner></button> :
                <button type="button" className="noborder buttonspace" onClick={startSpeechRecognition} style={{ marginLeft: "-5px", marginRight: '5px' }} ><img src={microphone} width="32" height="32" alt="send button" /></button>
              } */}
              <TextareaAutosize
                // type="text"
                className="form-control form-rounded input"
                onKeyDown={onEnterPress}
                onClick={textclick}
                value={input}
                onChange={event => setInput(event.target.value)}
                id="exampleFormControlInput1"
                placeholder="Type message"
                rows="1"
                style={{ overflow: "auto", height: "auto", backgroundColor: "#f5f6f7", borderRadius: "1.5rem" }}
                ref={inputRef}>
              </TextareaAutosize>
              <button type="button" className="noborder buttonspace" onClick={handleSubmit}><img src={paperplane} width="32" height="32" alt="send button" /></button>
            </div>
          </div>
      </div>
    );
  }
}
export default DashTextComponent