import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc, updateDoc, collection, addDoc } from "firebase/firestore";
import { info, danger, toxic, wait, errorToast } from "./toasts.js"
import { auth, logout, db } from "./firebase.js";
import paperplane from "./assets/paper2.png"
import update from 'immutability-helper';
import ReactGA from "react-ga4";
import { StatusBar } from '@capacitor/status-bar';
import { removeUser } from "./firebase.js";
import { getSubscribeInfo, handleManageSubscription } from './Subscribe.js';
import DashTextComponent from "./DashTextComponent.js";
import DashVoiceComponent from "./DashVoiceComponent.js";
import Sidebar from "react-sidebar";
import DashboardMenu from "./Sidebar.js";
import { ToastContainer } from 'react-toastify';
import Modals from "./Modals.js";
import { Keyboard } from '@capacitor/keyboard';
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';
import { isChrome } from 'react-device-detect';
import { fetchWithTimeout } from "./utils.js";

import { accent_color, num_free_messages, num_standard_messages, backend_url, llm_server } from "./variables.js"

import 'react-toastify/dist/ReactToastify.css';
import './css/Dashboard.css';
import './css/index.css';

import { Capacitor } from "@capacitor/core"

const getStatusBarHeight = async () => {
  const { height } = await SafeArea.getStatusBarHeight();
  return height;
};

const version = "1.6"

// small adjustments to the layout depending on the platform
let topmargin
let buttonMargin
let heightHeader
let position
let platform
let scroll
let heightBody
let welcome_msg

if (Capacitor.isNativePlatform()) {
  welcome_msg = "Hello and welcome, I'm Serena and I'm here to provide you with open-ended and personalized emotional support. You can also talk to me using the voice interface, which can be selected from the sidebar. I'm excited to start our journey together!"
  Keyboard.setResizeMode({ mode: 'native' });
  platform = Capacitor.getPlatform();
  if (platform === "ios") {
    getStatusBarHeight().then((val) => {
      topmargin = 0.9 * val;
      if (window.screen.availHeight < 1000) {
        heightHeader = 0.11 * window.screen.availHeight
      }
      else {
        heightHeader = 0.06 * window.screen.availHeight
      }

      position = "relative"
      buttonMargin = "0px"
      scroll = "smooth"
      heightBody = "100vh"
    })
  }
  // android
  else {
    StatusBar.setBackgroundColor({ color: "#696fe2" });
    topmargin = "0px"
    heightHeader = 0.05 * window.screen.availHeight
    position = "sticky"
    buttonMargin = "0rem"
    scroll = "auto"
    heightBody = "100vh"
  }

}
// web
else {
  welcome_msg = "Hello and welcome, I'm Serena and I'm here to provide you with open-ended and personalized emotional support. I'm excited to start our journey together!"
  topmargin = "0px"
  heightHeader = "3rem"
  buttonMargin = "0px"
  position = "sticky"
  scroll = "auto"
  if (isChrome) {
    heightBody = "calc(100vh - 3.5rem)"
  }
  else {
    heightBody = "calc(100dvh)"
  }
}
ReactGA.initialize("G-WV7T86637X");

const subscribe_active = true

function Dashboard() {
  const [text, setText] = useState("");
  const [input, setInput] = useState("");
  const [messages, setMessage] = useState([]);
  const [width, setWidth] = useState("80vw");

  const [receiving, setReceiving] = useState(false);
  const [log, setLog] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [speaking, setSpeaking] = useState(false);

  const [showVoice, setShowVoice] = useState(false);
  const [userStatus, setUserStatus] = useState("user");
  const [parameters, setParameters] = useState("")

  const [subscribeStatus, setSubscribeStatus] = useState("")
  const [subscribeTier, setSubscribeTier] = useState("")
  const [standardUsage, setStandardUsage] = useState(0)
  const [isRecording, setIsRecording] = useState(false)

  const [stripeId, setStripeId] = useState("");
  const [total_msg, setTotalMsg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const platformRef = useRef(platform)

  const playedWelcomeRef = useRef(false)


  const message_id = useRef(0);
  const recordRef = useRef("")
  const date = new Date().toLocaleDateString('fr-CH');
  const height = "100vh"

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const api_key = process.env.REACT_APP_API_KEY;

  const onSetSidebarOpen = (open) => {
    setSidebarOpen(open);
  };

  const toastId = React.useRef(null);

  const mediaMatch = window.matchMedia('(max-device-width: 1100px)');
  const [onMobile, setOnMobile] = useState(mediaMatch.matches);

  //todo: reduce amount of firebase calls

  useEffect(() => {
    const handler = e => setOnMobile(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  useEffect(() => {
    if (onMobile) {
      setWidth("100vw");
    }
  }, [onMobile]);

  const updateSubscribeInfo = useCallback(async (user) => {
    try {
      const subscribeInfo = await getSubscribeInfo(user);
      setSubscribeStatus(subscribeInfo.subscribe_status);
      setSubscribeTier(subscribeInfo.subscribe_tier);
      setStandardUsage(subscribeInfo.standard_usage);
    }
    catch (error) {
      console.log(error);
    }
  }, []);
  //update subscriptions status whenever subscrition modal or sidebar opens or closes
  useEffect(() => {
    updateSubscribeInfo(user);
  }, [showSubscriptionModal, updateSubscribeInfo, sidebarOpen, user]);

  const fetchParameters = useCallback(async () => {
    const requestOptions = {
      method: 'GET'
    };
    let json
    try {
      const res = await fetch(`${backend_url}/get_parameters`, requestOptions);
      json = await res.json();
    }
    catch (error) {
      console.log(error)
      json = {
        "max_new_tokens": 250,
        "stop": ["\n", "Therapist:"],
        "do_sample": true,
        "temperature": 0.3,
        "repetition_penalty": 1.2
      }
    }
    setParameters(json)
  }, []);

  const playSpeech = async (text, animate) => {
    const idToken = await user.getIdToken(true);
    const speechOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'access_token': api_key,
        'Authorization': 'Bearer ' + idToken,
      },
      body: JSON.stringify({ text: text }),
    };
    const speech_res = await fetchWithTimeout(`${backend_url}/speech_secure`, { timeout: 10000, ...speechOptions });
    // Convert the response to a Blob
    const blob = await speech_res.blob();

    // Create an object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a new Audio object and play it
    const audio = new Audio(url);

    await new Promise((res, rej) => {
      audio.onabort = function () {
        setSpeaking(false);
        rej(new Error("Audio failed to play"));
      };

      audio.onerror = function () {
        setSpeaking(false);
        rej(new Error("Audio failed to play"));
      };

      if (animate) { setSpeaking(true) }


      audio.play().then(() => {
        audio.onended = function () {
          res()
          setSpeaking(false)
        };
        setTimeout(() => {
          if (!audio.ended) {
            setSpeaking(false)
            rej(new Error("Audio did not finish within 30 seconds"));
          } else {
            res();
          }
        }, 30000);
      })
    })
  }



  const fetchUserData = useCallback(async (user) => {
    if (!user) return;
    const userRef = doc(db, "users", user?.uid);

    // retry three times to get data from firestore, initial load can be very slow
    for (let i = 0; i < 6; i++) {
      try {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const name = docSnap.data()["name"];
          setUserStatus(docSnap.data()["status"])
          setTotalMsg(docSnap.data()["total_msg"]);
          if (subscribe_active) {
            setStripeId(docSnap.data()["stripeId"])
          }
          if (name === "__init") {
            continue;
          };
          setLog(docSnap.data()["archive_anon"]);
          const history = docSnap.data()["history"];
          const welcome_msgs = ["How are you today?", "How are you feeling today?", "What's on your mind today?", "What would you like to talk about?"];
          const welcome = welcome_msgs[Math.floor(Math.random() * welcome_msgs.length)];
          const init_msg_txt = welcome
          const init_msg = [{ user: "bot", text: init_msg_txt, id: 0 }];
          // alert(history)
          if (history === "") {
            await updateDoc(userRef, { "history": init_msg });
            setMessage(init_msg);
            return
          }
          else {
            setMessage(history);
            return;
          }
        } else {
          //sleep for two second and retry
          await new Promise(r => setTimeout(r, 2000));
        }
      }
      catch (error) {
        if (i === 5) {
          alert("Failed to load user data. Please restart the app.")
        }
        console.log("failed, retrying. Error:", error);
        await new Promise(r => setTimeout(r, 2000));
      }
      if (i === 5) {
        alert("Failed to load user data. Please restart the app.")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkFirstTime = useCallback(async (user) => {
    const userRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const total_msg = docSnap.data()["total_msg"];
      if (total_msg === 0) {
        if (user.emailVerified) {
          await updateDoc(userRef, { "verified": true });
        }
        return true;
      }
      else return false
    } else {
      console.log("No such document!");
    }
  }, []);

  // this is used to decide whether the survey should be triggered
  // only show survey if user does not have an active subscription
  const checkSurvey = useCallback(async (user) => {
    const userRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const total_msg = docSnap.data()["total_msg"];
      const survey = docSnap.data()["survey"];
      if (total_msg > 25 && survey === "" && subscribeStatus !== "active") return true;
      else return false
    } else {
      console.log("No such document!");
    }
  }, [subscribeStatus]);

  // check if the metric document has been created today already, if not do so
  const initMetric = useCallback(async () => {
    const date = new Date().toLocaleDateString('fr-CH');
    const respRef = doc(db, "metrics_new", date);
    const respSnap = await getDoc(respRef);
    if (respSnap.exists() === false) {
      await setDoc(respRef, { "num_messages": 0, "users": 0 });
    }
  }, []);

  const handleManageSubscriptionCallback = useCallback(async (user) => {
    await handleManageSubscription(setIsLoading, user, stripeId, backend_url, fetchWithTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeId]);

  useEffect(() => {
    async function initialization() {
      if (loading) return;
      if (!user) return navigate("/");
      await fetchUserData(user)
      await initMetric()
      await fetchParameters()
      const first = await checkFirstTime(user)
      if (first && !playedWelcomeRef.current) {
        setShowInfoModal(true)
      }
      else {
        setReceiving(false)
      }
      setFirstTime(first)
    }
    initialization().catch(error => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, fetchUserData, checkFirstTime, initMetric]);

  // const fetchResponse = async (userRef, userSnap) => {
  //   //The first call gets the message history parsed as a string and truncated
  //   const idToken = await user.getIdToken(true);
  //   const requestOptionsString = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'access_token': api_key,
  //       'Authorization': 'Bearer ' + idToken,
  //     },
  //     body: JSON.stringify({ text: messages, version: version })
  //   };
  //   const resString = await fetch(`${backend_url}/respond`, requestOptionsString);
  //   const jsonString = await resString.json();
  //   //The second call sends the string to the LLM and gets the response as a stream
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ inputs: jsonString.history_string, parameters: parameters })
  //   };

  //   const res = await fetchWithTimeout(llm_server, { timeout: 30000, ...requestOptions });

  //   const reader = res.body.getReader();
  //   let done, value;
  //   var chunks = ""
  //   // Add new empty message to the list
  //   const newArray = update(messages, { $push: [{ id: messages.length, text: '', user: "bot" }] });
  //   var updatedMessages = [];
  //   const index = newArray.findIndex(item => item["id"] === messages.length);

  //   while (!done) {
  //     ({ value, done } = await reader.read());
  //     let str = new TextDecoder().decode(value);
  //     if (str.length === 0) {
  //       continue
  //     }
  //     const matches = str.match(/data/g);
  //     // handle the case when the response chunk contains several tokens
  //     if (matches.length > 1) {
  //       let substrings = str.split('data:');
  //       for (let i = 1; i < substrings.length; i++) {
  //         let json = JSON.parse(substrings[i])
  //         if (json.token && json.token.special === false) {
  //           // if (containsEmoji(json.token.text) && chunks.length < 2) {
  //           //   console.log('The text contains an emoji. Not adding it to the message.');
  //           // }
  //           // else{
  //           //   chunks += json.token.text
  //           // }
  //           chunks += json.token.text
  //         }
  //       }
  //     }
  //     // handle the case when the response chunk contains only one token
  //     else {
  //       str = str.substring(5)

  //       str = JSON.parse(str)
  //       if (str.token && str.token.special === false) {
  //         // if (containsEmoji(str.token.text) && chunks.length < 2) {
  //         //   console.log('The text contains an emoji. Not adding it to the message.');
  //         // }
  //         // else{
  //         //   chunks += str.token.text
  //         // }
  //         chunks += str.token.text
  //       }
  //     }

  //     // Fill the new empty message with the streaming response
  //     updatedMessages = update(newArray, {
  //       [index]: {
  //         text: {
  //           $set: chunks
  //         }
  //       }
  //     });
  //     setMessage(updatedMessages);
  //   }

  //   // update message history in firestore
  //   if (done) {
  //     if (showVoice) {
  //       await playSpeech(chunks, true)
  //     }
  //     if (userSnap.exists()) {
  //       await updateDoc(userRef, { "history": updatedMessages });
  //     }
  //   }
  // }

  const fetchResponse = async (userRef, userSnap) => {
    //The first call gets the message history parsed as a string and truncated
    const idToken = await user.getIdToken(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'access_token': api_key,
        'Authorization': 'Bearer ' + idToken,
      },
      body: JSON.stringify({ text: messages, version: version })
    };

    const res = await fetchWithTimeout(`${backend_url}/respond_secure`, { timeout: 30000, ...requestOptions });

    // Throw an error if the response code is not 200
    if (!res.ok) {
      throw new Error("Response code was not 200")

    }

    const reader = res.body.getReader();
    let done, value;
    var chunks = ""
    // Add new empty message to the list
    const newArray = update(messages, { $push: [{ id: messages.length, text: '', user: "bot" }] });
    var updatedMessages = [];
    const index = newArray.findIndex(item => item["id"] === messages.length);

    while (!done) {
      ({ value, done } = await reader.read());
      let str = new TextDecoder().decode(value);
      if (str.length === 0) {
        continue
      }
      chunks = chunks + str
      // Fill the new empty message with the streaming response
      updatedMessages = update(newArray, {
        [index]: {
          text: {
            $set: chunks
          }
        }
      });
      setMessage(updatedMessages);
    }

    // update message history in firestore
    if (done) {
      if (showVoice) {
        await playSpeech(chunks, true)
      }
      if (userSnap.exists()) {
        await updateDoc(userRef, { "history": updatedMessages });
      }
    }
  }


  // with this hook we fetch a response from the API
  useEffect(() => {
    async function getResponse() {
      if (text === "") return;
      const userRef = doc(db, "users", user?.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const last_msg = messages.find(item => item.id === messages.length - 1);
        // check if message is toxic or indicates self-harm
        const moderationOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'access_token': api_key,
          },
          body: JSON.stringify({ text: last_msg.text }),
        };
        var self_harm = false
        var flagged = false
        try {
          const moderation_url = `${backend_url}/moderate`
          const moderation_res = await fetchWithTimeout(moderation_url, { timeout: 10000, ...moderationOptions });
          const moderation_json = await moderation_res.json();
          self_harm = moderation_json.self_harm
          flagged = moderation_json.flagged
        }
        // If moderation fails we set the results to false
        catch (error) {
          console.log(error);
          self_harm = false;
          flagged = false;
        }

        if (self_harm) {
          danger(toastId);
          setMessage(messages.filter(item => item.id !== messages.length - 1));
          setReceiving(false);
        }
        else if (flagged) {
          toxic(toastId);
          setMessage(messages.filter(item => item.id !== messages.length - 1));
          setReceiving(false);
        }
        else if (text !== "" && last_msg.user === 'user') {
          if (!subscribe_active) {
            checkSurvey(user).then((check) => {
              if (check) navigate("/survey")
            }
            ).catch(error => console.log(error));
          }
          await fetchResponse(userRef, userSnap)
          setReceiving(false);
        }
      }
    }
    getResponse().catch(error => {
      setMessage(messages.filter(item => item.id !== messages.length - 1));
      setReceiving(false)

      if (showVoice) {
        // playSpeech("Sorry, something went wrong. Please try again.", true)
        errorToast()
        setSpeaking(false)
      }
      else {
        errorToast(toastId)
        // setAnimate(false)
      }
      console.log("error", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message_id.current]);

  const updateMessages = () => {
    const newItem = {
      text: input,
      user: 'user',
      id: messages.length
    };
    setMessage(oldArray => [...oldArray, newItem]);
    setText(input);
    setInput("");
  }
  const handleSubmit = useCallback(async (event) => {
    // this prevents the keyboard from closing after user submits message
    if (input === "") { return }
    if (event) { inputRef.current.focus(); }
    recordRef.current = ""
    if (subscribe_active && total_msg >= num_free_messages && subscribeStatus !== "active" && userStatus !== "admin") {
      setShowSubscriptionModal(true)
    }
    else if (subscribe_active && standardUsage >= num_standard_messages && subscribeTier === "Standard Plan" && subscribeStatus === "active" && userStatus !== "admin") {
      setShowSubscriptionModal(true)
    }
    else {
      if (receiving === false) {
        if (input !== "") {
          setReceiving(true)
          if (event) { event.preventDefault(); }
          message_id.current = message_id.current + 1;
          updateMessages()
          // Keep track of daily amount of users and sent messages
          const metricRef = doc(db, "metrics_new", date);
          const metricSnap = await getDoc(metricRef);
          // initialize metric document if it does not exist yet
          if (metricSnap.exists() === false) {
            await setDoc(metricRef, { "num_messages": 0, "users": 0 });
          }
          const userRef = doc(db, "users", user?.uid);
          const userSnap = await getDoc(userRef);

          var new_num = metricSnap.data()["num_messages"]
          // only count messages for users with activated logging
          if (log) {
            new_num += 1;
          }
          // Check if msg_cnt exists for today, if not set it up and increase number of daily users by one
          if (userSnap.exists()) {
            if (userSnap.data()["msg_cnt"][date] === undefined) {
              await updateDoc(userRef, { "msg_cnt": { [date]: 0 } })
              await updateDoc(metricRef, { "users": metricSnap.data()["users"] + 1, "num_messages": new_num })
            }
            else {
              await updateDoc(metricRef, { "num_messages": new_num })

            }
          }
          // log send message with google analytics
          // only users with activated logging count
          if (log) {
            ReactGA.event({
              category: "Messages",
              action: "Message sent"
            });
          }
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            setTotalMsg(docSnap.data()["total_msg"] + 1);
            await updateDoc(userRef, { "total_msg": docSnap.data()["total_msg"] + 1 });
            await updateDoc(userRef, { "msg_cnt": { [date]: docSnap.data()["msg_cnt"][date] + 1 } });
            if (subscribe_active && subscribeStatus === "active" && subscribeTier === "Standard Plan") {
              await updateDoc(userRef, { "standard_usage": docSnap.data()["standard_usage"] + 1 });
            }
          }
        }
      }
      else {
        wait(toastId);
      }
    }
    await updateSubscribeInfo(user)
  }, [input])

  // on retry: remove last answer by bot and query new one 
  const handleRetry = event => {
    // keep the keyboard open if it is already open
    // check if keyboard open
    if (window.innerHeight / window.screen.availHeight < 0.8) {
      inputRef.current.focus();
    }
    event.preventDefault();
    if (messages.length > 2 && receiving === false) {
      setReceiving(true)
      const last_msg = messages.find(item => item.id === messages.length - 1);
      //only allow to delete last message by bot
      if (last_msg.user === 'bot') {
        message_id.current = message_id.current + 1;
        setMessage(messages.filter(item => item.id !== messages.length - 1));
        // this handles the case when user tries to regenerate a response after refreshing the page
        if (text === "") {
          setText(messages[messages.length - 2].text);
        }
      }
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      try {
        handleSubmit(e)
      }
      catch (error) {
        console.log(error)
      }
    }
  }

  const reset = async () => {
    setReceiving(true);
    setMessage([]);
    try {
      const userRef = doc(db, "users", user?.uid);
      if (log) {
        await addDoc(collection(db, "archive"), {
          date: date,
          history: messages
        })
      }
      await updateDoc(userRef, { "history": "" });
      await fetchUserData(user, true);
      setReceiving(false);
    }
    catch (error) {
      console.log("Problem with updating Firestore: ", error)
    }

  }
  const handleInfo = () => {
    info(toastId);
  }

  const textclick = () => {
    setClickCount(clickCount + 1);
  }

  const deleteAcc = async () => {

    // often deleting the account fails because firebase complains that the user did not recently sign in
    // we therefore check if the login is older than one minute and if so, we let the user sign in again
    const lastSignInTime = new Date(user.metadata.lastSignInTime);
    const currentTime = new Date();
    const timeDiff = (currentTime - lastSignInTime) / 1000; // Convert to seconds
    if (timeDiff > 60) {
      setShowDeleteModal(false)
      setShowSignModal(true)
    }
    else { await removeUser(user) }
  }
  let mainComponent
  if (showVoice) {
    mainComponent = <DashVoiceComponent
      accent_color={accent_color}
      input={input}
      setInput={setInput}
      inputRef={inputRef}
      heightBody={heightBody}
      heightHeader={heightHeader}
      handleSubmit={handleSubmit}
      position={position}
      topmargin={topmargin}
      setSidebarOpen={setSidebarOpen}
      isRecording={isRecording}
      platform={platformRef.current}
      setIsRecording={setIsRecording}
      speaking={speaking}
      playSpeech={playSpeech}
      receiving={receiving}
      subscribeStatus={subscribeStatus}
      subscribeTier={subscribeTier}
      total_msg={total_msg}
      standardUsage={standardUsage}
      num_free_messages={num_free_messages}
      userStatus={userStatus}
      num_standard_messages={num_standard_messages}
      setShowSubscriptionModal={setShowSubscriptionModal}
      subscribe_active={subscribe_active}
    />
  }
  else {
    mainComponent = <DashTextComponent
      accent_color={accent_color}
      messages={messages}
      receiving={receiving}
      handleRetry={handleRetry}
      clickCount={clickCount}
      input={input}
      setInput={setInput}
      inputRef={inputRef}
      handleSubmit={handleSubmit}
      paperplane={paperplane}
      heightBody={heightBody}
      heightHeader={heightHeader}
      position={position}
      topmargin={topmargin}
      setSidebarOpen={setSidebarOpen}
      containerRef={containerRef}
      MessageList={MessageList}
      buttonMargin={buttonMargin}
      onEnterPress={onEnterPress}
      textclick={textclick}
      onMobile={onMobile}
      height={height}
      width={width}
    />
  }
  return (
    <>

      <Sidebar
        sidebar={
          <DashboardMenu userStatus={userStatus} handleInfo={handleInfo} reset={reset} logout={logout} setShowSubscriptionModal={setShowSubscriptionModal} setShowInfoModal={setShowInfoModal} subscribe_active={subscribe_active} user={user} setShowDeleteModal={setShowDeleteModal} showVoice={showVoice} setShowVoice={setShowVoice} platform={platformRef.current} />
        }
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: accent_color, width: "250px" } }}
      >
        <ToastContainer />
        {mainComponent}
      </Sidebar>
      <Modals showSubscriptionModal={showSubscriptionModal} setShowSubscriptionModal={setShowSubscriptionModal} total_msg={total_msg} handleManageSubscription={handleManageSubscriptionCallback} showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} isLoading={isLoading} showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteAcc={deleteAcc} showSignModal={showSignModal} setShowSignModal={setShowSignModal} />
    </>
  )
}
function Message(props) {
  if (props.item.user === "bot") {
    return (
      <div className="d-flex flex-row justify-content-start mb-2" style={{ marginLeft: -30 }}>
        <div>
          <p
            className="small p-2 mb-1 ms-2 rounded1"
            style={{ backgroundColor: "#f5f6f7" }}
          >
            <span >{props.item.text}</span>
          </p>
        </div>
      </div>
    )
  }
  if (props.item.user === "user") {
    return (
      <div className="d-flex flex-row justify-content-end mb-2">
        <div>
          <p className="small p-2 me-2 mb-1 text-white rounded1" style={{ backgroundColor: accent_color }}>
            {props.item.text}
          </p>
        </div>
      </div>
    )
  }
}

const MessageList = React.memo(function MessageList(props) {
  const num_messages = props.items.length;
  const [isTyping, setTyping] = useState(true);
  const newRef = useRef();

  const receiving = props.receiving;
  const messages = props.items;
  const handleRetry = props.handleRetry;

  let bodyPaddingTop
  let marginBottomTyping
  if (Capacitor.isNativePlatform()) {
    const platform = Capacitor.getPlatform();
    Keyboard.addListener('keyboardDidShow', info => {

      if (newRef && newRef.current) {
        newRef.current.scrollIntoView({ behavior: scroll });
      }
    });
    if (platform === "ios") {
      bodyPaddingTop = "0px"
      marginBottomTyping = "0px"
    }
    else {
      bodyPaddingTop = "0rem"
      marginBottomTyping = "0px"
    }
  }
  else {
    bodyPaddingTop = "0px"
    marginBottomTyping = "0px"
  }

  // check if last message is by user, in which case display typing animation
  useEffect(() => {
    const lastuser = props.items[props.items.length - 1]?.user;
    if (lastuser === "bot") setTyping(false);
    if (lastuser === "user") setTyping(true);
    if (num_messages === 0) setTyping(true);
    // eslint-disable-next-line
  }, [props]);

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      elementRef.current.scrollIntoView({ behavior: scroll });
    });
    return <div ref={elementRef} />;
  };

  const Typing = () => (
    <div className="typing" style={{ marginBottom: marginBottomTyping }}>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  )

  return (
    <ul className="message-list" style={{ paddingTop: bodyPaddingTop }}>
      <Message item={{ user: "bot", text: welcome_msg }} />
      {props.items.map(item => (
        <li ref={newRef} key={item.id} className="nobull">
          <Message item={{ user: item.user, text: item.text }} />
        </li>
      ))}

      <div className="d-flex justify-content-center">
        {(messages.length % 2 !== 0 && messages.length > 2 && receiving === false) ? <button type="button" className="btn btn-outline-dark btn-sm rounded2 border-1 hover-overlay" onClick={handleRetry} style={{ position: "relative", fontSize: 12, marginRight: "1rem" }}>Regenerate response</button> : <div></div>}
      </div>

      {isTyping ? (<Typing />) : null}
      {num_messages > 1 ? <AlwaysScrollToBottom /> : null}
    </ul>
  )
})
export default Dashboard;