import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import logorounded from "./assets/icon-512.webp"
import { auth, sendPasswordReset } from "./firebase";
import "./css/Reset.css";
import { SafeArea } from '@aashu-dubey/capacitor-statusbar-safe-area';

import { Capacitor } from "@capacitor/core"

import { accent_color } from "./variables.js"

const getStatusBarHeight = async () => {
  const { height } = await SafeArea.getStatusBarHeight();
  return height;
};

let topmargin
let signInTopMargin
let platform
if (Capacitor.isNativePlatform()) {
  platform = Capacitor.getPlatform();
  getStatusBarHeight().then((val) => {
    topmargin = val;
    if (platform === "ios") {
      signInTopMargin = val + 0.2*window.screen.availHeight
    }
    else{
      signInTopMargin = 0.25*window.screen.availHeight
    }
  })

}
else {
  signInTopMargin = 0.1*window.screen.availHeight
  topmargin = "0px"
  platform = "web"
}

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading] = useAuthState(auth);
  const [margin, setMargin] = useState("-7.5px");
  const navigate = useNavigate();

  //// this whole code is just to detect narrow mobile screen and adjust position of text
  const mediaMatch = window.matchMedia('(max-width: 1000px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  useEffect(() => {
    if (matches) {
      setMargin("0px");
    }
  }, [matches]);

  const reset = () => {
    sendPasswordReset(email).then(
      (value) => {
        console.log(value)
        if(value === "success") navigate("/");
      }
    ).catch(e => console.log(e))
  };

  ////
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  return (
    <div>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <a href="http://serena.chat" className="logo me-auto" style={{"marginLeft": margin, "marginTop": topmargin}}><img src={logorounded} alt="" className="img-fluid" />
            <strong className="h1title">Serena</strong></a>
        </div>
      </header>
      <div className="container ex-2-header" style={{"position": "fixed"}}>
      <div className="row">
        <div className="col-lg-12" style={{position: "absolute", top: signInTopMargin}}>
          <h1>Reset Password</h1>
          <p>
            {/* <span className="bodymyfont">No password? Please{" "}</span>
            <a className="white bodymyfont" href="./Register">
              Sign Up
            </a> */}
          </p>
          {/* Reset Form */}
          <div className="form-container">
            <div className="form-group">
              <input
                type="text"
                className="form-control-input inputmyfont"
                id="lemail"
                required=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <div className="help-block with-errors" />
            </div>
            <div className="form-group">
            <button className="form-control-submit-button buttonmyfont" onClick={reset}>
              Reset
            </button>
            </div>
            <div className="form-message">
              <div id="lmsgSubmit" className="h3 text-center hidden" />
            </div>
            <a href="./"  style={{ color: accent_color, fontSize: 12}} >Return to Login</a>
          </div>
        </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="reset">
  //     <div className="reset__container">
  //       <input
  //         type="text"
  //         className="reset__textBox"
  //         value={email}
  //         onChange={(e) => setEmail(e.target.value)}
  //         placeholder="E-mail Address"
  //       />
  //       <button className="reset__btn" onClick={() => sendPasswordReset(email)}>
  //         Send password reset email
  //       </button>

  //       <div>
  //         Don't have an account? <Link to="/register">Register</Link> now.
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default Reset;