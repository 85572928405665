import { useState, useCallback, useEffect } from 'react';
import { db, auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, addDoc, collection, updateDoc, onSnapshot, getDoc, getDocs } from "firebase/firestore";
import { loadStripe } from '@stripe/stripe-js';
import { Button, Spinner, Col, Row } from 'react-bootstrap';
import { Capacitor } from "@capacitor/core"
import {
  Purchases,
  LOG_LEVEL
} from '@revenuecat/purchases-capacitor';
import './css/Subscribe.css';
import * as RevenueCat from "@revenuecat/purchases-js";

import { accent_color, num_standard_messages, standard_price, premium_price, stripe_standard_price, stripe_premium_price, standard_trial_price, premium_trial_price } from "./variables.js"

let platform
if (Capacitor.isNativePlatform()) {
  platform = Capacitor.getPlatform();
}
else {

}

const checkSubscription = (entitlements) => {
  let tier, status
  if (typeof entitlements.active["Standard"] !== "undefined") {
    tier = "Standard Plan";
    status = "active";
  }
  else if (typeof entitlements.active["Premium"] !== "undefined") {
    tier = "Premium Plan";
    status = "active";
  }
  else {
    tier = "No Plan";
    status = "inactive";
  }
  return { "subscribe_tier": tier, "subscribe_status": status }
}

export const getSubscribeInfo = async (user) => {

  const userRef = doc(db, "users", user?.uid);
  const userSnap = await getDoc(userRef);
  let usage, tier, status
  if (userSnap.exists()) {
    usage = userSnap.data()["standard_usage"];
  }
  if (Capacitor.isNativePlatform()) {
    console.log("native")
    let apiKey
    if (platform === "ios") {
      apiKey = "appl_EDUJmaBdRLifaBBLkTGBOljOhMn"
    }
    else if (platform === "android") {
      apiKey = "goog_bwoJsyGureYYVPVdLlgzADSUBSK"
    }

    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    await Purchases.configure({
      apiKey: apiKey,
      appUserID: user.uid
    });
    const customerInfo = await Purchases.getCustomerInfo();
    if (typeof customerInfo.customerInfo.entitlements.active["Standard"] !== "undefined") {
      tier = "Standard Plan";
      status = "active";
    }
    else if (typeof customerInfo.customerInfo.entitlements.active["Premium"] !== "undefined") {
      tier = "Premium Plan";
      status = "active";
    }
    else {
      tier = "No Plan";
      status = "inactive";
    }
  }
  // web
  else {
    // check on web if user has an iOS/Android subscription
    RevenueCat.Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
    RevenueCat.Purchases.configure('rcb_SADZYqhWkDsjvnQVwurNTwfxWcMP', user.uid
    );
    const customerInfo = await RevenueCat.Purchases.getSharedInstance().getCustomerInfo();
    const subscriptionInfo = checkSubscription(customerInfo.entitlements)

    tier = subscriptionInfo.subscribe_tier
    status = subscriptionInfo.subscribe_status
    console.log(tier, status)
    // if there is no iOS/android subscription, check if user has a stripe subscription
    if (status !== "active") {
      const subscriptionsRef = collection(db, "users", user?.uid, "subscriptions");
      const querySnapshot = await getDocs(subscriptionsRef);
      const subscriptions = [];
      querySnapshot.forEach((doc) => {
        subscriptions.push({ id: doc.id, ...doc.data() });
      });
      if (subscriptions.length > 0) {
        //Sort in descending order such that the most recent subscription is first
        subscriptions.sort((a, b) => b.created - a.created);
        tier = subscriptions[0].items[0].price.product.name;
        status = subscriptions[0].status;
      }
    }
  }
  return { "standard_usage": usage, "subscribe_tier": tier, "subscribe_status": status }

};

export const handleManageSubscription = async (setIsLoading, user, stripeId, backend_url, fetchWithTimeout) => {
  setIsLoading(true);
  const PortalOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customer: stripeId }),
  };
  const portal_url = `${backend_url}/customer_portal`;
  try {
    const session_res = await fetchWithTimeout(portal_url, { timeout: 10000, ...PortalOptions });
    const customer_portal = await session_res.json();

    if (customer_portal.url) { window.location.href = customer_portal.url }
    else {
      setIsLoading(false)
      alert("An error occured, please try again in a few moments.")
    }
  } catch (e) {
    console.log(e)
    setIsLoading(false)
    alert("An error occured, please try again in a few moments.")
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
};

function Subscribe({ setShowSubscriptionModal, setSidebarOpen }) {
  const [user,] = useAuthState(auth);
  const [standardIsLoading, setStandardIsLoading] = useState(false);
  const [premiumIsLoading, setPremiumIsLoading] = useState(false);
  const mediaMatch = window.matchMedia('(max-width: 600px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const stripe_publishable_key = 'pk_live_51LxvhnKdUZsLS2B5WumgodLuCMt5veVGHdVllooE69RBreRKSMzxrf6QfDHZQl2vJM9oqqqHOjxHtqhEiJctNryt00ijZjdsMp'

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  });

  const handleSubscribe = useCallback(async (plan) => {
    let price
    if (plan === "standard") {

      price = stripe_standard_price
      const userRef = doc(db, "users", user?.uid);
      // this counts the number of sent messages in the current subscription period
      await updateDoc(userRef, { "standard_usage": 0 })
    }
    else if (plan === "premium") {

      price = stripe_premium_price
    }
    if (user) {
      const userRef = doc(db, "users", user.uid)
      const checkoutRef = collection(userRef, "checkout_sessions")

      const newSubscriptionRef = await addDoc(checkoutRef, {
        price: price,
        allow_promotion_codes: true,
        success_url: "https://my.serena.chat/dashboard",
        cancel_url: "https://my.serena.chat/dashboard"
      });
      onSnapshot(newSubscriptionRef, async (snap) => {
        console.log(snap.data())
        const { sessionId } = snap.data();
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          const stripe = await loadStripe(stripe_publishable_key);
          stripe.redirectToCheckout({ sessionId });
          setStandardIsLoading(false);
          setStandardIsLoading(false);
        }
      });
    }
  }, [user]);

  const handleSubscribeMobile = async (plan) => {
    if (plan === "standard") {
      setStandardIsLoading(true);
      const userRef = doc(db, "users", user?.uid);
      // this counts the number of sent messages in the current subscription period
      await updateDoc(userRef, { "standard_usage": 0 })
    }
    else if (plan === "premium") {
      setPremiumIsLoading(true);
    }
    const platform = Capacitor.getPlatform();
    let apiKey
    if (platform === "ios") {
      apiKey = "appl_EDUJmaBdRLifaBBLkTGBOljOhMn"
    }
    else if (platform === "android") {
      apiKey = "goog_bwoJsyGureYYVPVdLlgzADSUBSK"
    }
    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    await Purchases.configure({
      apiKey: apiKey,
      appUserID: user.uid // Optional
    });
    //this allows to send events to Firebase, we need this to track renewal of subscriptions
    // await Purchases.shared.attribution.setFirebaseAppInstanceID("1:595110206151:ios:b2df2f3bd572bf3d98fce2")
    const offerings = await Purchases.getOfferings();
    if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
      console.log(offerings)
      const standardPackage = offerings.current.availablePackages.find(p => p.identifier === 'Standard');
      const premiumPackage = offerings.current.availablePackages.find(p => p.identifier === 'Premium');
      let selectedPackage
      if (plan === "standard") {
        selectedPackage = standardPackage
      }
      else if (plan === "premium") {
        selectedPackage = premiumPackage
      }
      try {
        await Purchases.purchasePackage({ aPackage: selectedPackage });
        setShowSubscriptionModal(false);
        setSidebarOpen(false);
        // await updateSubscribe(user, setStandardUsage, setSubscribeTier, setSubscribeStatus)
      } catch (error) {
        console.log(error)
      }
      setStandardIsLoading(false);
      setPremiumIsLoading(false);
    }
  }

  const handleStandardClick = () => {
    setStandardIsLoading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        handleSubscribeMobile("standard");
      }
      else {
        handleSubscribe("standard");
      }
    } catch (err) {
      setPremiumIsLoading(false);
      setStandardIsLoading(false);
      console.log(err)
    }
  }
  const handlePremiumClick = () => {
    setPremiumIsLoading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        handleSubscribeMobile("premium");
      }
      else {
        handleSubscribe("premium");
      }
    } catch (err) {
      setPremiumIsLoading(false);
      setStandardIsLoading(false);
      console.log(err)
    }
  }
  if (!matches) {
    // This is return on large displays 
    return (
      <div>
        <Row >
          <h4 className="text-start" style={{ width: "100%" }}>Limited time offer!</h4>
          <Col xs={6} className="d-flex flex-column" style={{ position: "relative", borderRight: "1px solid #dee2e6", height: "100%" }}>

            <h5>Standard <del>$9.99</del> ${standard_price}/mo</h5>
            <p>{num_standard_messages} messages</p>
            <Button
              variant="secondary"
              onClick={handleStandardClick}
              style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "220px", fontSize: "16px" }}
              disabled={standardIsLoading}
            >
              {standardIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Standard"}
            </Button>
            <div style={{ position: "absolute", top: "50%", left: "-1px", width: "1px", height: "50%", content: "", backgroundColor: "#dee2e6" }}></div>
          </Col>
          <Col xs={6} className="d-flex flex-column">
            <h5>Premium <del>$29.99</del> ${premium_price}/mo</h5>
            <p>Unlimited messages</p>
            <Button
              variant="secondary"
              onClick={handlePremiumClick}
              style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "220px", fontSize: "16px" }}
              disabled={premiumIsLoading}
            >
              {premiumIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Premium"}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
  else {
    // this is for mobile 
    return (
      <div className="mx-auto">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="w-100 mb-3 text-center">
            <h5 style={{ width: "100%" }}>Standard </h5>
            <p>{num_standard_messages} messages</p>
            <p>1 month trial ${standard_trial_price}, then $5.99/mo</p>
            <Button
              variant="secondary"
              onClick={handleStandardClick}
              style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "140px", height: "55px", fontSize: "14px" }}
              disabled={standardIsLoading}
            >
              {standardIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Standard"}
            </Button>
          </div>
          <div style={{ borderLeft: '1px solid #dee2e6', height: 'auto', margin: '0 20px' }} />

          <div className="w-100 mb-3 text-center">
            <h5>Premium </h5>
            <p>No message limit</p>
            <p>1 month trial ${premium_trial_price}, then ${premium_price}/mo</p>
            <Button
              variant="secondary"
              onClick={handlePremiumClick}
              style={{ backgroundColor: accent_color, outline: "none", borderColor: accent_color, width: "140px", height: "55px", fontSize: "14px" }}
              disabled={premiumIsLoading}
            >
              {premiumIsLoading ? <Spinner animation="border" size="sm" /> : "Subscribe to Premium"}
            </Button>
          </div>
        </div>
      </div>
    );

  }
}

export default Subscribe;