import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from 'react-bootstrap';
import Subscribe from "./Subscribe";
import { useNavigate } from "react-router-dom";
import { logout, auth } from "./firebase"
import { num_free_messages, num_standard_messages } from "./variables";
import { getSubscribeInfo } from "./Subscribe";
import { useAuthState } from "react-firebase-hooks/auth";
import { Capacitor } from "@capacitor/core"


function Modals({ showSubscriptionModal, setShowSubscriptionModal, showInfoModal, setShowInfoModal, total_msg, handleManageSubscription, isLoading, showDeleteModal, setShowDeleteModal, showSignModal, setShowSignModal, deleteAcc, setSidebarOpen }) {
    const [user,] = useAuthState(auth);
    const [subscribeStatus, setSubscribeStatus] = useState("")
    const [subscribeTier, setSubscribeTier] = useState("")
    const [standardUsage, setStandardUsage] = useState(0)
    const navigate = useNavigate();

    const backToLogin = () => {
        logout()
        setTimeout(() => {
            navigate("/")
        }, 500);
    }

    let isNative
    let manageNote
    let infoNote
    if (Capacitor.isNativePlatform()) {
        infoNote = "You can talk to Serena using either the voice or text mode, which can be switched in the sidebar.\n\n"
        isNative = true
        if (Capacitor.getPlatform() === "ios") {
            manageNote = "You can manage your subscription in the iOS settings app."

        }
        else {
            manageNote = "You can manage your subscription in the Google Play Store."
        }
    }
    else {
        isNative = false
        infoNote = ""
        manageNote = ""
    }

    useEffect(() => {
        async function updateSubscribeInfo() {
            try {
                const subscribeInfo = await getSubscribeInfo(user)
                setSubscribeStatus(subscribeInfo.subscribe_status)
                setSubscribeTier(subscribeInfo.subscribe_tier)
                setStandardUsage(subscribeInfo.standard_usage)
            }
            catch (e) {
                console.log(e)
            }
        }
        updateSubscribeInfo()
    }, [showSubscriptionModal, user])
    return (
        <div>
            <Modal show={showSubscriptionModal} onHide={() => setShowSubscriptionModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Your Subscription Plan</Modal.Title>
                </Modal.Header>
                {/*Show loading animation when subscription data not yet*/}
                <Modal.Body>
                    {subscribeStatus === "" ?
                        <Spinner animation="border" role="status">
                            <span className="sr-only"></span>
                        </Spinner> :
                        <p>   {subscribeStatus !== "active" ? (
                            <>
                                {total_msg <= num_free_messages ? (
                                    <>
                                        <div style={{ fontSize: "15px" }}>You don't have a subscription and have used {total_msg}/{num_free_messages} free messages.</div>
                                    </>
                                ) : (
                                    `You do not have an active subscription and have used ${num_free_messages}/${num_free_messages} free messages. Please subscribe to raise or remove the message limit.`
                                )}
                            </>
                        ) : (
                            <>
                                {subscribeTier === "Standard Plan" ? (
                                    <>
                                        {standardUsage <= num_standard_messages ? (
                                            `You are on the Standard Plan and have used ${standardUsage}/${num_standard_messages} monthly messages. ${manageNote}`
                                        ) : (
                                            `You are on the Standard Plan and have used ${num_standard_messages}/${num_standard_messages} monthly messages. Please upgrade to Premium to get unlimited messages.`
                                        )}
                                    </>
                                ) : (
                                    `You are on the Premium Plan and have unlimited messages. ${manageNote}`
                                )}
                            </>
                        )}</p>
                    }

                </Modal.Body>
                {(subscribeStatus === "active" && isNative) || subscribeStatus === "" ? null :
                    <Modal.Footer>
                        {subscribeStatus === "active" ?
                            <Button
                                style={{ backgroundColor: "#696fe2", outline: "none", borderColor: "#696fe2", width: "180px" }}
                                variant="primary"
                                onClick={handleManageSubscription}
                                disabled={isLoading}>
                                {isLoading ? <Spinner animation="border" size="sm" /> : "Manage Subscription"}
                            </Button> :
                            <div style={{ margin: 'auto', width: 'fit-content' }}>
                                <Subscribe setShowSubscriptionModal={setShowSubscriptionModal} setSidebarOpen={setSidebarOpen} />
                                <p style={{ fontSize: "12px", marginTop: "15px" }}> All subscriptions renew on a monthly basis, and your account will be debited each month unless you cancel before the beginning of the billing cycle. Trial subscriptions are converted to full price subscriptions on renewal.</p>
                            </div>
                        }
                    </Modal.Footer>
                }
            </Modal>

            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to Serena! 🎉</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {infoNote}
                    </div>
                    <div>
                        As an AI model, Serena can sometimes make wrong assumptions or misunderstand your messages.
                        <b> Please notice that Serena should not be relied upon for medical advice!</b> <br></br> <br></br> We hope you enjoy talking to Serena! </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start">
                    <a href="https://serena.chat/legal#terms" style={{ fontSize: '14px' }}>Terms of Service</a>
                    <a href="https://serena.chat/legal#privacy" style={{ fontSize: '14px' }}>Privacy Policy</a>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Account deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>This will delete your account and chat history with Serena. It cannot be recovered. Your subscription is tied to this account, so after deleting you won't have access to it anymore! Are you sure you want to proceed?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ backgroundColor: "#696fe2", outline: "none", borderColor: "#696fe2", width: "180px" }}
                        variant="primary"
                        onClick={deleteAcc}
                        disabled={isLoading}>
                        Delete Account
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSignModal} onHide={() => setShowSignModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Account deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>As a security measure, you have to first login again before you can delete your account.</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ backgroundColor: "#696fe2", outline: "none", borderColor: "#696fe2", width: "180px" }}
                        variant="primary"
                        onClick={backToLogin}
                        disabled={isLoading}>
                        Login again
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Modals;