import React from "react";
import logo from "./assets/logo_roundwhite.png"
import { useNavigate } from "react-router-dom";


function DashboardMenu({ userStatus, reset, logout, setShowSubscriptionModal, setShowInfoModal, setShowDeleteModal, subscribe_active, setShowVoice, showVoice, platform }) {
  const navigate = useNavigate();
  const handleSwitch = () => {
    if(showVoice){
      setShowVoice(false)
    }
    else{
      setShowVoice(true)
    }
  }
  return (
    <div>
      <img src={logo} alt="logo" style={{ width: "60px", margin: "auto", display: "block", paddingTop: 0.08 * window.screen.availHeight, paddingBottom: "20px", marginBottom: "20px" }} />
      {/* <button onClick={() => onSetSidebarOpen(false)}>Close</button> */}
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li> <button className="sidebar-button" onClick={() => setShowInfoModal(true)}>Info</button></li>

        {platform === "ios" || platform === "android" ?  
        <li><button className="sidebar-button" onClick={handleSwitch}>{showVoice ? "Switch to text mode" : "Switch to voice mode"}</button></li> : null}
        {userStatus === "admin" ? <li><button className="sidebar-button" onClick={reset}>Restart</button></li> : null}

        {subscribe_active === true ? <li><button className="sidebar-button" onClick={() => setShowSubscriptionModal(true)}>Subscription</button></li> : null}
        <li><button className="sidebar-button" onClick={() => navigate("/survey")}>Feedback</button></li>
        <li><button className="sidebar-button" onClick={() => setShowDeleteModal(true)}>Delete Account</button></li>
        <li><button className="sidebar-button" onClick={logout}>Logout</button></li>
      </ul>
    </div>
  );
}

export default DashboardMenu;