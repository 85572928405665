export const accent_color = "#696fe2"
export const stripe_publishable_key = "pk_live_51LxvhnKdUZsLS2B5WumgodLuCMt5veVGHdVllooE69RBreRKSMzxrf6QfDHZQl2vJM9oqqqHOjxHtqhEiJctNryt00ijZjdsMp"
export const num_free_messages = 15
export const num_standard_messages = 2500
export const backend_url = "https://fastapi.serena.chat"
// export const backend_url = "https://fastapi.marketinference.com"
export const llm_server = "https://llm.serena.chat/generate_stream"
// export const llm_server = "https://serenatest.marketinference.com/generate_stream"
export const standard_price = 5.99
export const standard_trial_price = 0.99
export const premium_price = 15.99
export const premium_trial_price = 4.99
export const stripe_standard_price = "price_1ONdgfKdUZsLS2B5FuQr5bOi"
export const stripe_premium_price = "price_1ONdedKdUZsLS2B5kbYJRk4Y"