import "./css/App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import Welcome from "./Welcome";
// import Subscribe from "./Subscribe";
import SurveyComponent from "./SurveyComponent";
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';

const history = createBrowserHistory();


ReactGA.initialize("G-WV7T86637X");

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <div className="app">
      <Router history={history}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/welcome" element={<Welcome />} />
          {/* <Route exact path="/subscribe" element={<Subscribe />} /> */}
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/survey" element={<SurveyComponent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
